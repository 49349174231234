import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import manage from './manage'
import params from './params'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        manage,
        params,
    }
});