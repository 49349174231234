import { service_axios } from '@/main';

export default {
    namespaced: true,
    actions: {
        async getListBranch(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/branch", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getAddBranch(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/branch", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getEditBranch(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/branch", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getChangeBranch(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/branch", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getManageCargo(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/cargo", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getManageDriver(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/driver", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getManageCustomer(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/customer", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getManagePackage(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("manage/package", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },




    }
}