import { service_axios } from '@/main';

export default {
    namespaced: true,
    state: {
        drawer: true,
        clipped: true,
        loading: false,
        loading2: false,
        loading3: false,
        isLogin: false,
        fullname: "",
        branchname: "",
        changepassword: "",
        process: 0,
    },
    getters: {
        loading: state => state.loading,
        isLogin: state => state.isLogin
    },
    mutations: {
        setLogin(state, status) {
            state.isLogin = status
        },
        setLoading(state, status) {
            state.loading = status
        },
        setLoading2(state, status) {
            state.loading2 = status
        },
        setLoading3(state, status) {
            state.loading3 = status
        },
        setFullname(state, status) {
            state.fullname = status
        },
        setBranchName(state, status) {
            state.branchname = status
        },
        setIsChangePassword(state, status) {
            state.changepassword = status
        },
        setProcess(state, val) {
            state.process = val
        }
    },
    actions: {
        async getAuthLogin(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("auth/login", data).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
        async getListMenu(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.get("auth/menu", {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
    }

}