import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import '@/plugins/vuetify-numeric'
import '@/plugins/vue-file-agent'
import '@/assets/tailwind.css'
import "@/plugins/vuehtmltopaper";
import '@/plugins/vue-barcode';
import axios from 'axios'
import swal from "sweetalert2";
import helper from "@/helper";

export const service_axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 40000,
});

service_axios.interceptors.response.use(
    function(response) {
        if (response.status == 200) {
            try {
                return response.data;
            } catch (error) {
                swal.fire({
                    title: 'Oops',
                    text: response.data.message,
                    icon: "error",
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                }).then(async result => {
                    if (result.value) {
                        await window.sessionStorage.removeItem("accessToken");
                        await window.sessionStorage.removeItem("fullname");
                        await window.sessionStorage.removeItem("brname");
                        await window.sessionStorage.removeItem("changepassword");
                        await store.commit("auth/setIsLoading", false);
                        await store.commit("auth/setIsLoading2", false);
                        await store.commit("auth/setIsLoading3", false);
                        if (router.app["_route"].name !== "login") {
                            await router.push({ name: "login" });
                        }
                    }
                });
            }
        }
    },
    function(error) {
        if (error.code == 'ERR_NETWORK') {
            swal.fire({
                title: 'Oops',
                text: error.message,
                icon: "error",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then(async result => {
                if (result.value) {
                    await window.sessionStorage.removeItem("accessToken");
                    await window.sessionStorage.removeItem("fullname");
                    await window.sessionStorage.removeItem("brname");
                    await window.sessionStorage.removeItem("changepassword");
                    await store.commit("auth/setIsLoading", false);
                    await store.commit("auth/setIsLoading2", false);
                    await store.commit("auth/setIsLoading3", false);
                    if (router.app["_route"].name !== "login") {
                        await router.push({ name: "login" });
                    }
                }
            });
        } else if (error.code == 'ECONNABORTED') {
            swal.fire({
                title: 'Oops',
                text: error.message,
                icon: "error",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then(async result => {
                if (result.value) {
                    await window.sessionStorage.removeItem("accessToken");
                    await window.sessionStorage.removeItem("fullname");
                    await window.sessionStorage.removeItem("brname");
                    await window.sessionStorage.removeItem("changepassword");
                    await store.commit("auth/setIsLoading", false);
                    await store.commit("auth/setIsLoading2", false);
                    await store.commit("auth/setIsLoading3", false);
                    if (router.app["_route"].name !== "login") {
                        await router.push({ name: "login" });
                    }
                }
            });
        } else if (error.response.status == 401) {
            swal.fire({
                title: 'Oops',
                text: error.response.data.message,
                icon: "error",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then(async result => {
                if (result.value) {
                    await window.sessionStorage.removeItem("accessToken");
                    await window.sessionStorage.removeItem("fullname");
                    await window.sessionStorage.removeItem("brname");
                    await window.sessionStorage.removeItem("changepassword");
                    await store.commit("auth/setIsLoading", false);
                    await store.commit("auth/setIsLoading2", false);
                    await store.commit("auth/setIsLoading3", false);
                    if (router.app["_route"].name !== "login") {
                        await router.push({ name: "login" });
                    }
                }
            });
        } else if (error.response.status == 405) {
            swal.fire({
                title: 'Oops',
                text: error.response.data.message,
                icon: "error",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then(async result => {
                if (result.value) {
                    await window.sessionStorage.removeItem("accessToken");
                    await window.sessionStorage.removeItem("fullname");
                    await window.sessionStorage.removeItem("brname");
                    await window.sessionStorage.removeItem("changepassword");
                    await store.commit("auth/setIsLoading", false);
                    await store.commit("auth/setIsLoading2", false);
                    await store.commit("auth/setIsLoading3", false);
                    if (router.app["_route"].name !== "login") {
                        await router.push({ name: "login" });
                    }
                }
            });
        } else if (error.response.status == 500) {
            swal.fire({
                title: 'Oops',
                text: error.response.data.message,
                icon: "error",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then(async result => {
                if (result.value) {
                    await window.sessionStorage.removeItem("accessToken");
                    await window.sessionStorage.removeItem("fullname");
                    await window.sessionStorage.removeItem("brname");
                    await window.sessionStorage.removeItem("changepassword");
                    await store.commit("auth/setIsLoading", false);
                    await store.commit("auth/setIsLoading2", false);
                    await store.commit("auth/setIsLoading3", false);
                    if (router.app["_route"].name !== "login") {
                        await router.push({ name: "login" });
                    }
                }
            });
        } else {
            swal.fire({
                title: 'Oops',
                text: error.message,
                icon: "error",
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
            }).then(async result => {
                if (result.value) {
                    await window.sessionStorage.removeItem("accessToken");
                    await window.sessionStorage.removeItem("fullname");
                    await window.sessionStorage.removeItem("brname");
                    await window.sessionStorage.removeItem("changepassword");
                    await store.commit("auth/setIsLoading", false);
                    await store.commit("auth/setIsLoading2", false);
                    await store.commit("auth/setIsLoading3", false);
                    if (router.app["_route"].name !== "login") {
                        await router.push({ name: "login" });
                    }
                }
            });
        }
    }
);

const Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});

Vue.prototype.$service_axios = service_axios;
Vue.prototype.$helper = helper;
Vue.prototype.$swal = swal;
Vue.prototype.$toast = Toast;

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')