import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';

let basePath = 'http://localhost:8082/';
const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        `printstyle.css`,

    ],
    timeout: 1000,
    autoClose: true,
    windowTitle: window.document.title,
}

Vue.use(VueHtmlToPaper, options);

Vue.use(VueHtmlToPaper);