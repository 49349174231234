import Vue from 'vue'
import VueRouter from 'vue-router'
import helper from '@/helper'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        { path: '*', name: '*', redirect: 'PageNotFound', },
        {
            path: '/login',
            name: 'login',
            component: () => {
                return import ('@/views/Login.vue')
            }
        },
        {
            path: '/',
            name: 'layout',
            component: () => {
                return import ('@/views/Layout.vue')
            },
            beforeEnter: (to, from, next) => {
                const isAuthenticated = helper.isAuthUsersLogin();
                if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
                next()
            },
            children: [{
                path: '/main',
                name: 'main',
                component: () => {
                    return import ('@/views/pages/Main.vue')
                },
            }, {
                path: '/import',
                name: 'import',
                component: () => {
                    return import ('@/views/pages/Import.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຮັບພັດສະດຸຈາກລູກຄ້າ',
                        disabled: true,
                        href: '#/import',
                    }, ],
                },
            }, {
                path: '/import',
                name: 'importadd',
                component: () => {
                    return import ('@/views/pages/Import-add.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຮັບພັດສະດຸຈາກລູກຄ້າ',
                        disabled: false,
                        href: '#/import',
                    }, {
                        text: 'ເພີ່ມຂໍ້ມູນພັດສະດຸ',
                        disabled: true,
                        href: '#/import',
                    }, ],
                },
            }, {
                path: '/delivery',
                name: 'delivery',
                component: () => {
                    return import ('@/views/pages/Delivery.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດສົ່ງພັດສະດຸ',
                        disabled: true,
                        href: '#/delivery',
                    }, ],
                },
            }, {
                path: '/delivery',
                name: 'deliveryadd',
                component: () => {
                    return import ('@/views/pages/Delivery-Add.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດສົ່ງພັດສະດຸ',
                        disabled: false,
                        href: '#/delivery',
                    }, {
                        text: 'ສ້າງລາຍການຈັດສົ່ງພັດສະດຸ',
                        disabled: true,
                        href: '#/delivery',
                    }, ],
                },
            }, {
                path: '/wharehouse',
                name: 'wharehouse',
                component: () => {
                    return import ('@/views/pages/Wharehouse.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ລາຍການພັດສະດຸໃນສາງ',
                        disabled: true,
                        href: '#/wharehouse',
                    }, ],
                },
            }, {
                path: '/wharehouse',
                name: 'wharehouseadd',
                component: () => {
                    return import ('@/views/pages/Wharehouse-Add.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ລາຍການພັດສະດຸໃນສາງ',
                        disabled: false,
                        href: '#/wharehouse',
                    }, {
                        text: 'ຮັບພັດສະດຸລົງສາງ',
                        disabled: true,
                        href: '#/wharehouse',
                    }, ],
                },
            }, {
                path: '/receive',
                name: 'receive',
                component: () => {
                    return import ('@/views/pages/Receive.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ລາຍການເອົາພັດສະດຸໃຫ້ລູກຄ້າ',
                        disabled: true,
                        href: '#/receive',
                    }, ],
                },
            }, {
                path: '/receive',
                name: 'receiveadd',
                component: () => {
                    return import ('@/views/pages/Receive-Add.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ລາຍການເອົາພັດສະດຸໃຫ້ລູກຄ້າ',
                        disabled: false,
                        href: '#/receive',
                    }, {
                        text: 'ເອົາພັດສະດຸໃຫ້ລູກຄ້າ',
                        disabled: true,
                        href: '#/receive',
                    }, ],
                },
            }, {
                path: '/search',
                name: 'search',
                component: () => {
                    return import ('@/views/pages/Search.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຄົ້ນຫາພັດສະດຸ',
                        disabled: true,
                        href: '#/search',
                    }, ],
                },
            }, {
                path: '/users',
                name: 'users',
                component: () => {
                    return import ('@/views/pages/Users.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດການຂໍ້ມູນຜູ້ໃຊ້ລະບົບ',
                        disabled: true,
                        href: '#/users',
                    }, ],
                },
            }, {
                path: '/branch',
                name: 'branch',
                component: () => {
                    return import ('@/views/pages/Branch.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດການຂໍ້ມູນສາງ',
                        disabled: true,
                        href: '#/branch',
                    }, ],
                },
            }, {
                path: '/cargo',
                name: 'cargo',
                component: () => {
                    return import ('@/views/pages/Cargo.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດການຂໍ້ມູນລົດ',
                        disabled: true,
                        href: '#/cargo',
                    }, ],
                },
            }, {
                path: '/driver',
                name: 'driver',
                component: () => {
                    return import ('@/views/pages/Driver.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດການຂໍ້ມູນພະນັກງານຂັບລົດ',
                        disabled: true,
                        href: '#/driver',
                    }, ],
                },
            }, {
                path: '/customer',
                name: 'customer',
                component: () => {
                    return import ('@/views/pages/Customer.vue')
                },
                meta: {
                    breadcrumb: [{
                        text: 'ໜ້າທຳອິດ',
                        disabled: false,
                        href: '#/main',
                    }, {
                        text: 'ຈັດການຂໍ້ມູນລູກຄ້າ',
                        disabled: true,
                        href: '#/customer',
                    }, ],
                },
            }, {
                path: '/currency',
                name: 'currency',
                component: () => {
                    return import ('@/views/pages/Currency.vue')
                },
            }]

        },
    ]
})

export default router