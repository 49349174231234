import moment from 'moment'
import store from '@/store/index.js';

export default {
    isAuthUsersLogin() {
        const token = window.sessionStorage.getItem("accessToken");
        const fullname = window.sessionStorage.getItem("fullname");
        const brname = window.sessionStorage.getItem("brname");
        const changepassword = window.sessionStorage.getItem("changepassword");
        if (token) {
            store.commit("auth/setFullname", fullname);
            store.commit("auth/setBranchName", brname);
            store.commit("auth/setIsChangePassword", changepassword);
            return true;
        } else {
            return false;
        }
    },
    formatFontDate(date) {
        if (date == null) {
            return '';
        } else if (date == '') {
            return '';
        } else {
            return moment(new Date(date)).format("DD/MM/YYYY");
        }
    },
    formatFontTime(date) {
        if (date == null) {
            return '';
        } else if (date == '') {
            return '';
        } else {
            return moment(new Date(date)).format("HH:mm:ss");
        }
    },
    formatNumber(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign +
                (j ? i.substr(0, j) + thousands : '') +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            return amount;
        }
    }
}