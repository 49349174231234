import { service_axios } from '@/main';

export default {
    namespaced: true,
    actions: {
        async getListSelectBox(ctx, data) {
            return new Promise((resolve, reject) => {
                service_axios.post("params/selectboxdata", data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("accessToken")
                    }
                }).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(false)
                });
            });
        },
    }
}